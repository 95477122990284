import Vue from "vue";
import { Carousel, Slide } from "vue-carousel";
import "number-to-locale-string";
import Raven from 'raven-js';
import RavenVue from 'raven-js/plugins/vue';
import VueLazyload from 'vue-lazyload';
import loadingImg from '../../site/static/img/loading.png';
import CustomVideo from './components/CustomVideo.vue';
import leaflet from 'leaflet';
import 'leaflet/dist/leaflet.css';
import marker from 'leaflet/dist/images/marker-icon.png';
import marker2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

Object.assign(leaflet.Icon.Default.prototype.options, {
    iconUrl: marker,
    iconRetinaUrl: marker2x,
    shadowUrl: markerShadow,
});

import { i18n } from "./i18n";

if (process.env.NODE_ENV !== "development") {
    Raven
        .config('https://d0034ebf066743a5b7b7a253a4133a26@sentry.io/247219', {
            environment: process.env.CONTEXT,
            release: process.env.COMMIT_REF,
        })
        .addPlugin(RavenVue, Vue)
        .install();
}

Vue.use(VueLazyload, { preLoad: 1.5, loading: loadingImg });
Vue.filter('capitalize', (value) => {
    if (!value) return '';
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
});
Vue.filter('i18n', (value, key) => i18n(key, value));
Vue.mixin({
    data: function () {
        return { i18n };
    }
});


function main() {

    const languageSwitch = document.getElementById('switch-language');
    languageSwitch.classList.remove('hidden');
    languageSwitch.addEventListener('click', () => {
        const newLang = languageSwitch.dataset.lang;
        document.cookie = 'nf_lang=' + newLang + ';path=/';
    }, true);

    new Vue({
        el: '#calendar',
        components: {
            BookingFlow: () => import(/* webpackChunkName: "bookingflow" */ './components/BookingFlow.vue'),
        }
    });

    new Vue({
        el: '#pictures',
        components: {
            Carousel,
            Slide,
            CustomVideo,
        },
        data: {
            hasInteracted: false,
        },
        methods: {
            onInput() {
                this.hasInteracted = true;
                this.$el.querySelectorAll('video').forEach(v => v.pause());
            },
        },
    });

    const hero = document.querySelector('.hero');
    if (hero) {
        const img = window.innerWidth && window.innerWidth > 1500 ? '/img/view.jpg' : '/img/view_1500.jpg';
        const heroImg = new Image();
        heroImg.addEventListener('load', () => {
            hero.style.backgroundImage = 'url(' + img + ')';
            hero.classList.add('js-loaded');
        }, false);
        heroImg.src = img;
    }


    const position = [43.43044, 6.88286];
    const map = leaflet.map('map').setView(position, 13);
    leaflet.tileLayer('https://b.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);
    leaflet.marker(position).addTo(map)
        .bindPopup("<a target='_blank' href='https://www.openstreetmap.org/directions?from=&to=43.43047%2C6.88291#map=10/43.4295/6.8877'>549 Boulevard des Mimosas,<br>83700 Saint-Raphaël</a>")
        .openPopup();
}

function supportAllFeatures() {
    return window.Promise
        && window.Symbol
        && Array.prototype.findIndex
        && Array.prototype[Symbol.iterator]
        && Element.prototype.matches
        && Element.prototype.closest;
}

function loadScript(src, done) {
    var js = document.createElement('script');
    js.src = src;
    js.onload = function () {
        done();
    };
    js.onerror = function () {
        done(new Error('Failed to load script ' + src));
    };
    document.head.appendChild(js);
}

if (supportAllFeatures()) {
    main();
} else {
    loadScript("/polyfills.js", main);
}
