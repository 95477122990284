function substituteArgs(trans, args) {
    for (var i = 0; i < args.length; i++) {
        trans = trans.replace("{" + (i + 1) + "}", args[i]);
    }
    return trans;
}

export function i18n(key) {
    var trans = window.I18N[key].other;
    return substituteArgs(trans, [].slice.call(arguments, 1));
}

i18n.P = function (key, n) {
    var plural = window.I18N[key];
    var trans = n === 1 ? plural.one : plural.other;
    return substituteArgs(trans, [].slice.call(arguments, 2));
}
