<template>
    <div class="video-wrapper">
        <video ref="video" v-on:pause="paused" v-on:click="$refs.video.pause()" playsinline>
            <slot />
        </video>
        <div v-if="showOverlay" class="video-overlay" v-on:click="play">
            <div class="play-button"></div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showOverlay: true,
        };
    },
    methods: {
        play() {
            this.$refs.video.play();
            this.showOverlay = false;
        },
        paused() {
            this.showOverlay = true;
        },
    },
}
</script>
